<template>
  <div class="login-block">
    <loading v-if="cargando" />
    <div class="row">
      <!--<div class="col-md-6 offset-md-3 text-center">
        <img src="/img/icons/pegaso-icon.png" alt="">
      </div>-->
      <div class="container container-login">
        <div class="row">
          <div class="col-md-4 login-sec">
            <h2 class="text-center">
              <img
                src="/img/logo--fondo-fronteraenergy.png"
                alt=""
                style="width: 200px"
              />
            </h2>
            <form class="login-form" @submit.prevent="login">
              <div class="input-group mb-3">
                <input
                  type="email"
                  class="form-control"
                  v-model="form.email"
                  placeholder="Email"
                  :class="$v.form.email.$invalid ? 'is-invalid' : 'is-valid'"
                  autocomplete="off"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-envelope"></span>
                  </div>
                </div>
              </div>
              <div class="input-group mb-3">
                <input
                  type="password"
                  class="form-control"
                  v-model="form.password"
                  placeholder="Password"
                  :class="$v.form.password.$invalid ? 'is-invalid' : 'is-valid'"
                  autocomplete="off"
                />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <span class="fas fa-lock"></span>
                  </div>
                </div>
              </div>

              <div class="form-check">
                <label class="form-check-label">
                  <input type="checkbox" class="form-check-input" />
                  <small>Recordarme</small>
                </label>
                <button
                  type="submit"
                  class="btn btn-login float-right"
                  v-show="!$v.form.$invalid"
                  @click="validateAndSubmit"
                >
                  Ingresar
                </button>
              </div>

              <div class="input-group mb-3">
                <router-link to="/ForgotPassword"
                  >Deseo recordar mi contraseña</router-link
                >
              </div>
              <!--   <div class="input-group mb-3">
                <router-link to="/PoliticaTratamientoDatos"
                  >Tratamiento de datos personales</router-link
                >
              </div> -->

              <div class="row">
                <div class="col-md-12">
                  <button
                    type="button"
                    class="btn bg-frontera-top-left text-white w-100"
                    @click="SignIn"
                  >
                    <i class="fas fa-key"></i> Ingreso Frontera
                  </button>
                </div>

                <!-- <div class="col-md-12 mt-3">
                  <button
                    type="button"
                    class="btn bg-frontera-top-left text-white w-100"
                    data-toggle="modal"
                    data-target="#modal-form-casosSoporte"
                  >
                    <i class="fas fa-rocket"></i> Orion
                  </button>
                </div> -->
              </div>
            </form>
            <div class="row mt-3">
              <div class="col-md-12 text-center">
                <img src="/img/logo-sos.png" alt="" style="width: 80px" />
              </div>
            </div>
          </div>
          <div class="col-md-8 banner-sec">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-ride="carousel"
            >
              <ol class="carousel-indicators">
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="0"
                  class="active"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="1"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="2"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="3"
                ></li>
                <li
                  data-target="#carouselExampleIndicators"
                  data-slide-to="4"
                ></li>
              </ol>
              <div class="carousel-inner" role="listbox">
                <div class="carousel-item active">
                  <img
                    class="d-block img-fluid"
                    src="/img/banner_feria_sv1.png"
                    style="height: 460px;"
                    alt="First slide"
                  />
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block img-fluid"
                    src="/img/banerinicio1.png"
                    alt="First slide"
                    style="height: 460px;"
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <div class="banner-text">
                      <h2>Pegaso Crudo</h2>
                      <p>
                        Pegaso es el proceso de logística de transporte
                        terrestre de Frontera Energy más eficiente, transparente
                        y sostenible que optimiza la nominación y el enturne de
                        los transportadores.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block img-fluid"
                    src="/img/banerinicio2.png"
                    alt="First slide"
                    style="height: 460px;"
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <div class="banner-text">
                      <h2>Pegaso T.E.P</h2>
                      <p>
                        Pegaso es el proceso de logística de transporte
                        terrestre de Frontera Energy más eficiente, transparente
                        y sostenible que optimiza la nominación y el enturne de
                        los transportadores.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block img-fluid"
                    src="/img/banerinicio3.png"
                    alt="First slide"
                    style="height: 460px;"
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <div class="banner-text">
                      <h2>Pegaso T.I.F</h2>
                      <p>
                        Pegaso es el proceso de logística de transporte
                        terrestre de Frontera Energy más eficiente, transparente
                        y sostenible que optimiza la nominación y el enturne de
                        los transportadores.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <img
                    class="d-block img-fluid"
                    src="/img/banerinicio2.png"
                    alt="First slide"
                    style="height: 460px;"
                  />
                  <div class="carousel-caption d-none d-md-block">
                    <div class="banner-text">
                      <h2>Pegaso Carga seca</h2>
                      <p>
                        Pegaso es el proceso de logística de transporte
                        terrestre de Frontera Energy más eficiente, transparente
                        y sostenible que optimiza la nominación y el enturne de
                        los transportadores.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="modal fade" id="modal-form-casosSoporte">
            <div class="modal-dialog modal-sm">
              <div class="modal-content">
                <div class="modal-body">
                  <button
                    type="button"
                    class="close"
                    style="display: none;"
                    data-dismiss="modal"
                    aria-label="Close"
                    ref="closeModal"
                  ></button>
                  <div class="row justify-content-center">
                    <div class="col-md-5 mt-3">
                      <button
                        type="button"
                        class="btn bg-frontera-top-left text-white w-100"
                        @click="orionVehiculos"
                      >
                        <i class="fas fa-truck-pickup"></i><br />
                        Vehículos
                      </button>
                    </div>
                    <div class="col-md-5 mt-3">
                      <button
                        type="button"
                        class="btn bg-frontera-top-left text-white w-100"
                        @click="orionConductores"
                      >
                        <i class="fas fa-users-cog"></i> Conductores
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.login-block {
  background: #001871; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom right,
    #201747,
    #001871
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom right,
    #201747,
    #001871
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner-sec {
  border-radius: 0 10px 10px 0;
  padding: 0px;
}
.container-login {
  background: #fff;
  border-radius: 10px;
  box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}
.carousel-inner {
  border-radius: 0 10px 10px 0;
}
.carousel-caption {
  text-align: left;
  left: 5%;
}
.login-sec {
  padding: 50px 30px 10px 30px;
  position: relative;
}
.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
.login-sec .copy-text i {
  color: #001871;
}
.login-sec .copy-text a {
  color: #201747;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #001871;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #001871;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
.btn-login {
  background: #001871;
  color: #fff;
  font-weight: 600;
}
.banner-text {
  width: 70%;
  position: absolute;
  bottom: 40px;
  padding-left: 20px;
}
.banner-text h2 {
  color: #fff;
  font-weight: 600;
}
.banner-text h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #fff;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
}
.banner-text p {
  color: #fff;
}
</style>

<script>
import { required, email } from "vuelidate/lib/validators";
import Loading from "../components/Loading";
import { PublicClientApplication } from "@azure/msal-browser";

export default {
  components: {
    Loading,
  },
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    cargando: false,
    account: null,
    //  {
    //   environment: "login.windows.net",
    //   homeAccountId:"1e250963-6d90-45b7-be49-21c840951f6c.2637d50c-c560-436c-8997-ebe63c9656fd",
    //   idTokenClaims: {},
    //   localAccountId: "1e250963-6d90-45b7-be49-21c840951f6c",
    //   name: "p egaso",
    //   tenantId: "2637d50c-c560-436c-8997-ebe63c9656fd",
    //   username: "pegaso@fronteraenergy.ca",
    // },
  }),
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(
      this.$store.state.msalConfig
    );
  },
  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    this.loginAzure(this.account);
  },
  methods: {
    validateAndSubmit() {
      this.$recaptcha("login").then((token) => {
        this.form.recaptcha = token;
      });
    },

    /* async orionVehiculos() {
      this.$refs.closeModal.click();
      await this.$router.replace("/SolicitudVehiculo");
    },

    async orionConductores() {
      this.$refs.closeModal.click();
      await this.$router.replace("/SolicitudConductor");
    }, */

    async login() {
      this.cargando = true;
      await this.$store.dispatch("login", this.form);
      this.cargando = false;

      if (this.$store.getters.isAuthenticated) {
        return this.$router.replace("/");
      } else {
        const errorType = this.$store.getters.getErrors;

        switch (errorType) {
          case "recaptcha":
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Lo sentimos, hemos detectado que no pasa filtro de seguridad de Recaptcha.",
            });
            break;
          case "password_updated_at":
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Ha pasado mucho tiempo sin actualizar la contraseña, por favor cámbiela!",
            });
            return this.$router.push({
              name: "ResetPassword",
              params: {
                token: "token",
                email: this.form.email,
              },
            });
          case "status":
          case "estado_empresa":
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Lo sentimos, pero las credenciales que has proporcionado son incorrectas. Por favor, verifica la información ingresada e inténtalo nuevamente.",
            });
            break;
          case "email":
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: this.$store.getters.getMensajeLogin,
            });
            break;
          default:
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: "Lo sentimos, pero las credenciales que has proporcionado son incorrectas. Por favor, verifica la información ingresada e inténtalo nuevamente.",
            });
        }
      }
    },

    async loginAzure(cuentaAzure) {
      this.cargando = true;
      await this.$store.dispatch("loginAzure", cuentaAzure);
      if (this.$store.getters.isAuthenticated) {
        this.cargando = false;
        return this.$router.replace("/");
      } else {
        this.cargando = false;
        if (this.$store.getters.getErrors === "status") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Lo sentimos, pero las credenciales que has proporcionado son incorrectas. Por favor, verifica la información ingresada e inténtalo nuevamente.",
          });
        } else if (this.$store.getters.getErrors === "estado_empresa}") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Lo sentimos, pero las credenciales que has proporcionado son incorrectas. Por favor, verifica la información ingresada e inténtalo nuevamente.",
          });
        } else if (this.$store.getters.getErrors === "email") {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: this.$store.getters.getMensajeLogin,
          });
        } else {
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: "Lo sentimos, pero las credenciales que has proporcionado son incorrectas. Por favor, verifica la información ingresada e inténtalo nuevamente.",
          });
        }
      }
    },
    async SignIn() {
      await this.$msalInstance
        .loginPopup({})
        .then(() => {
          const myAccounts = this.$msalInstance.getAllAccounts();
          this.account = myAccounts[0];
          //logueo con azure
          this.loginAzure(this.account);
        })
        .catch((error) => {
          console.error(`error during authentication: ${error}`);
        });
    },
    async SignOut() {
      await this.$msalInstance
        .logout({})
        .then(() => {
          this.$emitter.emit("logout", "logging out");
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>